<template>
  <div>
    <div class="contents">
      <submenu></submenu>
      <div class="tableSearch">
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="90px">
                <el-form-item label="模板名称" style="margin-bottom:10px">

                  <el-input v-model="searchForm.name" clearable  style="width:190px"
                    placeholder="请输入模板名称"></el-input>
                </el-form-item>

                <span class="buttons">
                    <el-button @click="createSearch" type="primary" icon="el-icon-search" :loading="loading">查询</el-button>
                    <el-button @click="reset">重置</el-button>
                </span>
          </el-form>
      </div>

    <div class="mainbox">

     <vxe-toolbar ref="xToolbar" custom>
            <template #buttons>
              <el-button type="primary" class="tbBtn" icon="el-icon-plus" size="small" @click="handleAdd">添加</el-button>

            </template>
            <template #tools>
            </template>
      </vxe-toolbar>

      <vxe-table border
        id="table"
        empty-text="没有更多数据"
        header-align="center"
        stripe
        :data="tableData"
        :custom-config="{storage: true, resizable: true}"
        :column-config="{resizable: true}"
        :row-config="{isCurrent: true, isHover: true, keyField: 'id'}"
        size="small"
        @checkbox-all="handleSelectionChange"
        @checkbox-change="handleSelectionChange"
         ref="table">
          <vxe-column type="checkbox" width="40" fixed></vxe-column>


        <vxe-column
            field="name"
            title="模板名称"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.name}}
          </template>
        </vxe-column>
        <vxe-column
            field="num"
            title="打印联数"
            min-width="65"
        >
          <template slot-scope="scope">
                {{scope.row.num}}
          </template>
        </vxe-column>
        <vxe-column
            field="sheetName"
            title="电子面单"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.sheetName}}
          </template>
        </vxe-column>
        <vxe-column
            field="height"
            title="高度(mm)"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.height}}
          </template>
        </vxe-column>
        <vxe-column
            field="width"
            title="宽度(mm)"
            min-width="80"
        >
          <template slot-scope="scope">
                {{scope.row.width}}
          </template>
        </vxe-column>
        <vxe-column
            field="mainTemplateName"
            title="主单模板"
            min-width="150"
        >
          <template slot-scope="scope">
                {{scope.row.mainTemplateName}}
          </template>
        </vxe-column>
        <vxe-column
            field="childTemplateName"
            title="子单模板"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.childTemplateName}}
          </template>
        </vxe-column>
        <vxe-column
            field="backTemplateName"
            title="回单模板"
            min-width="120"
        >
          <template slot-scope="scope">
                {{scope.row.backTemplateName}}
          </template>
        </vxe-column>
        <vxe-column
            field="disable"
            title="状态"
            min-width="50"
        >
          <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.disable == 0">正常</el-tag>
                <el-tag type="danger" v-else>禁用</el-tag>
          </template>
        </vxe-column>

        <vxe-column title="操作" fixed="right" width="160">
            <template slot-scope="scope">
                  <el-button class="c-button" @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>

                  <el-button class="c-button" @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>

             </template>
        </vxe-column>

      </vxe-table>

      <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage" :current-page="page" :page-size="pageSize" @size-change="handleSizeChange"
            :total="total">
          </el-pagination>
      </div>
      </div>
    </div>

    <expresstemplate-add ref="wmsExpressTemplateAdd" @success="getTopSearch"></expresstemplate-add>
    <expresstemplate-detail ref="wmsExpressTemplateDetail" @success="getTopSearch"></expresstemplate-detail>
    <expresstemplate-edit ref="wmsExpressTemplateEdit" @success="getTopSearch"></expresstemplate-edit>

  </div>
</template>

<script>
import Vue from 'vue'
import Clipboard from 'clipboard'
import passWord from "@/components/password"
import backImg from "@/assets/logofang.png"
import expresstemplateAdd from "./wmsExpressTemplateAdd"
import expresstemplateDetail from "./wmsExpressTemplateDetail"
import expresstemplateEdit from "./wmsExpressTemplateEdit"

import { pageWmsExpressTemplate, detailWmsExpressTemplate, delWmsExpressTemplate, batchDeleteWmsExpressTemplate } from "@/api/wms/wmsExpressTemplate"
import { getEnums } from "@/api/common"
import { listWmsSheet } from "@/api/wms/wmsSheet"
import submenu from "@/components/submenu"

import { listWmsTemplateStyle } from "@/api/wms/wmsTemplateStyle"




let moment = require('moment')
Vue.prototype.Clipboard = Clipboard

export default {
  name: 'WmsExpressTemplate',
  components: { submenu, passWord, expresstemplateAdd, expresstemplateDetail, expresstemplateEdit },
  data() {
    return {
      searchForm: {
      },
      total: 0,
      page: 1,
      counts: 0,
      pageSize: 20,
      tableData: [],
      backImg: backImg,
      enums: {
      },
      enumMaps: {},
        optionsOfWmsSheet: [],

        optionsOfWmsTemplateStyle: [],



      loading: false,
      multipleSelection: [],
    }
  },
  methods: {
    initEnums() {
        const param = {
            names: Object.keys(this.enums)
        }
        getEnums(param).then(res=>{
            if(res.code===200) {
              this.enums = res.data.enums
              Object.keys(this.enums).forEach(key => {
                let map = {}
                this.enums[key].forEach(obj => map[obj.value] = obj.name)
                this.enumMaps[key] = map
              })
            } else {
                this.$message.error(res.message)
            }
        })
    },
    handleCommand(arg) {
        console.log("handleCommand", arg)
        this.multipleSelection = this.$refs.table.getCheckboxRecords()
        if(arg == "batchDelete") {
            return this.handleBatchDelete()
        }
    },
    handleBatchDelete() {
        console.log("handleBatchDelete", this.multipleSelection)
        if(this.multipleSelection.length ==0) {
            return this.$message.warning("请选择要批量删除的记录")
        }
        this.$confirm('确定要删除这'+ this.multipleSelection.length +'条数据吗，一旦删除不可恢复，请谨慎操作?', '温馨提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                 const param = {
                    ids: this.multipleSelection.map(it => it.id)
                 }
                 batchDeleteWmsExpressTemplate(param).then(res => {
                    if(res.code===200) {
                        this.$message.success(res.message)
                        this.getTopSearch()
                    } else {
                        this.$message.error(res.message)
                    }
                 })
        }).catch(err=>{})
    },
    handleAdd() {
      this.$refs.wmsExpressTemplateAdd.onOpen()
    },
    handleDetail(row) {
      this.$refs.wmsExpressTemplateDetail.onOpen(row)
    },
    handleEdit(row) {
      this.$refs.wmsExpressTemplateEdit.onOpen(row)
    },
    handleSelectionChange(val) {
        console.log("handleSelectionChange", val)
      this.multipleSelection = val.records
    },
    del(row) {
      const param = {
          id: row.id
      }
      del(param).then(res=>{
          if(res.code===200) {
            this.$message.success(res.message)
            this.getTopSearch()
          } else {
            this.$message.error(res.message)
          }
      })
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getTopSearch()
    },
    createSearch() {
      this.page = 1
      this.getTopSearch()
    },
    reset(){
      this.searchForm={
        date: ''
      }
      this.page = 1
      this.getTopSearch()
    },

    listWmsSheet(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSheet(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSheet = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsTemplateStyle(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsTemplateStyle(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsTemplateStyle = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },



    getTopSearch(){
      var that = this
      var param = that.searchForm
      param.pageNo = that.page
      param.pageSize = that.pageSize
      param.orderByCreated = "DESC"
      that.loading = true
      pageWmsExpressTemplate(param).then(res => {
        that.loading = false
        if(res.code ===200) {
          var nodataArr = res.data.rows || []
          that.tableData = nodataArr
          that.total = res.data.totalRows
          that.counts = res.data.counts
        }else{
          this.$message.error(res.message)
        }
      }).catch(err=>{
        that.loading = false
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
  },
  created() {
    this.initEnums()
    this.listWmsSheet()

    this.listWmsTemplateStyle()



    this.getTopSearch()
  },
  mounted(){
  }
}
</script>
<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}
.el-select {
    width: 190px;
}
.red {
  color: rgb(236, 78, 15);
}
.c-button{
  margin-left:8px;
}
.tbBtn {
  margin-right:10px;
}
.buttons {
}
</style>