import request from '@/api/request.js'

/**
 * @Description: 快递模板
 */

// 分页查询
export function pageWmsExpressTemplate(data) {
    return request({
        url: '/api/wmsExpressTemplate/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsExpressTemplate(data) {
    return request({
        url: '/api/wmsExpressTemplate/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsExpressTemplate(data) {
    return request({
        url: '/api/wmsExpressTemplate/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsExpressTemplate(data) {
    return request({
        url: '/api/wmsExpressTemplate/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsExpressTemplate(data) {
    return request({
        url: '/api/wmsExpressTemplate/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsExpressTemplate(data) {
    return request({
        url: '/api/wmsExpressTemplate/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsExpressTemplate(data) {
    return request({
        url: '/api/wmsExpressTemplate/batchDelete',
        method: 'POST',
        data
    })
}

