
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="编辑发货模板"
    :visible.sync="visible"
    width="740px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
<el-row>
        
        <el-col :span="12">
                <el-form-item label="模板名称" prop="name">
              <el-input v-model="row.name" placeholder="请输入模板名称" clearable
                :disabled="false"
                :style="{width: '200px'}"></el-input>
                </el-form-item>
        </el-col>
        
        <el-col :span="12">
                <el-form-item label="打印联数" prop="num">
              <el-input-number v-model="row.num" placeholder="请输入打印联数" clearable
                :controls="false"
                :step="1"
                :disabled="false"
                :style="{width: '200px'}"></el-input-number>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="12">
                <el-form-item label="电子面单" prop="sheetId">
                <el-select ref="sheetId"
                :disabled="false"
                                v-model="row.sheetId"
                                prop="sheetId"
                                placeholder="请选择电子面单"
                                clearable
                                filterable
                                :filter-method="listWmsSheet"
                            >
                            <el-option
                                v-for="item in optionsOfWmsSheet"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
                </el-form-item>
        </el-col>
        
        <el-col :span="12">
                <el-form-item label="高度(mm)" prop="height">
              <el-input-number v-model="row.height"
                :disabled="false"
                placeholder="请输入高度(mm)" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="12">
                <el-form-item label="宽度(mm)" prop="width">
              <el-input-number v-model="row.width"
                :disabled="false"
                placeholder="请输入宽度(mm)" clearable
                :controls="false"
                :step="1"
                :style="{width: '200px'}"></el-input-number>
                </el-form-item>
        </el-col>
        
        <el-col :span="12">
                <el-form-item label="主单模板" prop="mainTemplateId">
                <el-select ref="mainTemplateId"
                :disabled="false"
                                v-model="row.mainTemplateId"
                                prop="mainTemplateId"
                                placeholder="请选择主单模板"
                                clearable
                                filterable
                                :filter-method="listWmsTemplateStyle"
                            >
                            <el-option
                                v-for="item in optionsOfWmsTemplateStyle"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="12">
                <el-form-item label="子单模板" prop="childTemplateId">
                <el-select ref="childTemplateId"
                :disabled="false"
                                v-model="row.childTemplateId"
                                prop="childTemplateId"
                                placeholder="请选择子单模板"
                                clearable
                                filterable
                                :filter-method="listWmsTemplateStyle"
                            >
                            <el-option
                                v-for="item in optionsOfWmsTemplateStyle"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
                </el-form-item>
        </el-col>
        
        <el-col :span="12">
                <el-form-item label="回单模板" prop="backTemplateId">
                <el-select ref="backTemplateId"
                :disabled="false"
                                v-model="row.backTemplateId"
                                prop="backTemplateId"
                                placeholder="请选择回单模板"
                                clearable
                                filterable
                                :filter-method="listWmsTemplateStyle"
                            >
                            <el-option
                                v-for="item in optionsOfWmsTemplateStyle"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>
                </el-form-item>
        </el-col>
            </el-row>
            <el-row>
        
        <el-col :span="12">
                <el-form-item label="禁用" prop="disable">
                <el-radio-group v-model="row.disable"
                :disabled="false"                
                size="mini">
                    <el-radio :label="0">否</el-radio>
                    <el-radio :label="1">是</el-radio>
               </el-radio-group>
                </el-form-item>
        </el-col>
        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { editWmsExpressTemplate } from "@/api/wms/wmsExpressTemplate"

import { listWmsSheet } from "@/api/wms/wmsSheet"

import { listWmsTemplateStyle } from "@/api/wms/wmsTemplateStyle"




export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            name: null,
            num: null,
            sheetId: null,
            sheetName: null,
            height: null,
            width: null,
            mainTemplateId: null,
            mainTemplateName: null,
            childTemplateId: null,
            childTemplateName: null,
            backTemplateId: null,
            backTemplateName: null,
            disable: null,
      },
      rules: {
            name: [
                { required: true, message: '请输入模板名称!'},
            ],
            num: [
                { required: true, message: '请输入打印联数!'},
            ],
            sheetId: [
                { required: true, message: '请输入电子面单!'},
            ],
            height: [
                { required: true, message: '请输入高度(mm)!'},
            ],
            height: [
                { required: true, message: '请输入高度(mm)!'},
            ],
            width: [
                { required: true, message: '请输入宽度(mm)!'},
            ],
            width: [
                { required: true, message: '请输入宽度(mm)!'},
            ],
            mainTemplateId: [
                { required: true, message: '请输入主单模板!'},
            ],
            mainTemplateName: [
                { required: true, message: '请输入主单模板!'},
            ],
            disable: [
                { required: true, message: '请输入禁用!'},
            ],
      },
        enums: {},
        enumMaps: {},
        optionsOfWmsSheet: [],

        optionsOfWmsTemplateStyle: [],



    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
    this.listWmsSheet()

    this.listWmsTemplateStyle()



  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen(row) {
        this.visible = true
        this.row = row
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },
    listWmsSheet(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSheet(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSheet = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },

    listWmsTemplateStyle(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsTemplateStyle(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsTemplateStyle = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },



    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        editWmsExpressTemplate(param).then(res=>{
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
