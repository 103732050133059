import request from '@/api/request.js'

/**
 * @Description: 面单模板
 */

// 分页查询
export function pageWmsTemplateStyle(data) {
    return request({
        url: '/api/wmsTemplateStyle/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsTemplateStyle(data) {
    return request({
        url: '/api/wmsTemplateStyle/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsTemplateStyle(data) {
    return request({
        url: '/api/wmsTemplateStyle/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsTemplateStyle(data) {
    return request({
        url: '/api/wmsTemplateStyle/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsTemplateStyle(data) {
    return request({
        url: '/api/wmsTemplateStyle/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsTemplateStyle(data) {
    return request({
        url: '/api/wmsTemplateStyle/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsTemplateStyle(data) {
    return request({
        url: '/api/wmsTemplateStyle/batchDelete',
        method: 'POST',
        data
    })
}

